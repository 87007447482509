import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Homepage.css'
import logo from '../../assets/hmcLogoBlue.png'
import Nav from '../Nav/Nav'
import Footer from '../Footer/Footer'

const Homepage = () => {

  const [registrationNumber, setRegistrationNumber] = useState();
  const navigate = useNavigate();

  const viewStatus = () => {
    // if(registrationNumber){
    const encodedCaseId = encodeURIComponent(registrationNumber);
    navigate(`/viewregistrationstatus?id=${encodedCaseId}`)
    // }
  }

  return (

    <>

      <Nav />

      <div className='form-header home'>
        <img src={logo} className='logo home' />
        <div>Haryana Medical Council</div>
      </div>

      <div className='Homepage'>

        <div className='viewStatusDiv'>
          <div className='viewStatusHead'>
            View Your CME Registration Status
          </div>

          <form
            className='viewStatusFlex'
            onSubmit={(e) => {
              e.preventDefault(); // Prevent default form submission behavior
              viewStatus(); // Call the viewStatus function
            }}
          >
            <input
              placeholder='Enter Case ID'
              // type='number'
              onChange={(e) => setRegistrationNumber(e.target.value)}
              required
              value={registrationNumber}
            />
            <button className='home-button' type='submit'>
              View Status
            </button>
          </form>
        </div>


      </div>

      <Footer />
    </>
  )
}

export default Homepage
