import React from 'react'

const CertificatePage = () => {
  return (
    <div>
      this is the certi page
    </div>
  )
}

export default CertificatePage
