// import React, { useState } from 'react';
// import CryptoJS from 'crypto-js';
// import axios from 'axios';

// import { endpoint, testpayments } from '../constants/constant';

// const PaymentGateway = () => {
//     const [username, setUsername] = useState('');
//     const [caseId, setCaseId] = useState('');
//     const [amount, setAmount] = useState('');
//     const [formHtml, setFormHtml] = useState('');



//     const requestURLfromBackend = async () => {

//         try{

//         //   const data = await axios.get(`${endpoint}${testpayments}`)
//         //   console.log(data)
//         window.open(`${endpoint}/testpayment`, '_blank');
//         }catch(e){
//             console.log(e)
//         }

//     }
//     //  /payment

//     return (
//         <div>
//             <h1>Payment Gateway</h1>
//             <button onClick={()=>{requestURLfromBackend()}}>
//                 testPayment
//             </button>

//         </div>
//     );
// };

// export default PaymentGateway;


import React, { useEffect } from "react";
import axios from "axios";

const PaymentForm = () => {
    const testPayment = async () => {
        try {
            // Fetch the encrypted string from the backend
            const response = await axios.get("https://cme.ehssasdigitech.com/v1/generatepaymentstring");
            const encryptedString = response.data; // Assuming the API returns the string directly
            console.log(encryptedString)
            // Construct the URL
            const paymentUrl = `https://haryanamedicalcouncil.org/Home/cmeRegistration?string=${encryptedString}`;
            // const paymentUrl = `https://haryanamedicalcouncil.org/Home/cmeRegistration/string?=${encryptedString}`;

            // Open the URL in a new tab
            console.log(paymentUrl)
            // window.open(paymentUrl, "_blank");
            window.location.href = paymentUrl;
        } catch (error) {
            console.error("Error fetching payment string:", error);
            alert("Failed to generate payment string. Please try again.");
        }
    };

    return (
        <div>
            <h1>Payment Gateway</h1>
            <button onClick={testPayment}>
                testPayment
            </button>

        </div>
    );
};

export default PaymentForm;