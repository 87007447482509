import React, { useState } from 'react';
import '.././Dashboard.css';
import { useEffect } from 'react';
import axios from 'axios';
import useAuth from '../../../hooks/useAuth';
import AdminNav from '../../Nav/AdminNav';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { endpoint, getformdata, AccountantApproval } from '../../../constants/constant';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const predefinedLabels = [
    'Registration Number of Association / Organization with evidence',
    'List of the Faculty with detailed qualification and experience',
    'Detailed programme schedule mentioning time, subject and the name of the Speaker',
    'List of Topics',
    'Medical Registration Certificate of the Organizing Chairman',
    'Medical Registration Certificate of the Organizing Secretary',
    'Any Other Supporting Documents'
];

const AccountantApprovalPage = () => {
    const [formData, setformData] = useState({});
    const [paymentData, setPaymentData] = useState({})
    const { auth } = useAuth();
    const [token, settoken] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const { _id } = useParams();

    useEffect(() => {
        const tokendata = auth.RawToken;
        settoken(tokendata);

        const fetchFormData = async () => {
            try {
                const response = await axios.get(`${endpoint}${getformdata}?id=${_id}`, {
                    headers: {
                        Authorization: `Bearer ${tokendata}`,
                        Accept: 'application/json',
                    },
                });
                setformData(response.data[0]);
                setPaymentData(response.data[0].paymentData)
            } catch (error) {
                console.error(error);
            }
        };

        fetchFormData();
    }, [auth.RawToken, _id]);


    const approveCME = async (e) => {

        console.log(token);
        // console.log(e.target.id);

        try {
            const response = await axios.post(
                `${endpoint}${AccountantApproval}`,
                { _id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Replace with your actual token

                    }
                }
            );
            console.log(response.data)
            setformData(response.data);
            setPaymentData(response.data.paymentData)
            // const fd = response.data;
            // // console.log(fd[0].chairman)
            // setformData(e => fd[0])
            // console.log(formData)
            // setApprovals(response.data)

        } catch (e) { console.log(e) }
    }

    return (
        <div className="adminDashboard">
            <AdminNav />

            <div className="dashboardWrapper">
                <div className="dashboardHeader">
                    <h1 className="dashboardTitle">Accountant Dashboard</h1>
                </div>

                <div className="dashboardContent">
                    <div className="dashboardNav">
                        <div className="dashboardHeading">FORM DATA</div>
                        <div className="backButton" onClick={() => navigate(-1)}>
                            <ArrowBackIcon className="backIcon" />
                            Back to All Records
                        </div>
                    </div>

                    <div className="formDetailsSection">
                        <div className="formDetailsGrid">
                            <div className="formDetailsRow">
                                <strong>Organization Name: </strong>{formData?.organizationName}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Email: </strong>{formData?.email}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Address: </strong>{formData?.address}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Theme: </strong>{formData?.theme}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Registration Number: </strong>{formData?.registrationNumber}
                            </div>
                            <div className="formDetailsRow">
                                <strong>CME Start Date: </strong>{formData?.cmeStartDate}
                            </div>
                            <div className="formDetailsRow">
                                <strong>CME End Date: </strong>{formData?.cmeEndDate}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Days: </strong>{formData?.daysDifference}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Faculty 1: </strong>{formData?.faculty1}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Delegates: </strong>{formData?.delegates}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Exercise: </strong>{formData?.exercise}
                            </div>
                            <div className="formDetailsRow">
                                <strong>No of Hours: </strong>{formData?.hours}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Chairman: </strong>{formData?.chairman}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Secretary: </strong>{formData?.secretary}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Methodology: </strong>{formData?.methodology}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Permission Number: </strong>{formData?.permissionNumber}
                            </div>
                            <div className="formDetailsRow">
                                <strong>Permission Date: </strong>{formData?.permissionDate}
                            </div>
                        </div>

                        <div className="documentsSection">
                            <h3 className="sectionTitle">List of Documents:</h3>
                            <div className="documentsList">
                                {predefinedLabels?.map((label, index) => {
                                    const matchingPDF = formData?.pdfURL?.find(pdf => pdf.PDF_Label === label);

                                    return (
                                        <div key={index} className="documentRow">
                                            <div className="documentIndex">{index + 1}</div>
                                            <div className="documentLabel">{label}</div>

                                            {matchingPDF && matchingPDF.url ? (
                                                <a href={matchingPDF.url} className="viewButton" target="_blank" rel="noopener noreferrer">
                                                    <button className="viewButton">View</button>
                                                </a>
                                            ) : (
                                                <div className="emptyButton"></div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="paymentDetailsSection">
                            <h3 className="sectionTitle">Payment Details</h3>
                            <div
                                className="paymentDetailsGrid"
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "1fr 2fr",
                                    gap: "10px",
                                    padding: "15px",
                                    border: "1px solid #ccc",
                                    borderRadius: "8px",
                                    backgroundColor: "#f9f9f9",
                                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)"
                                }}
                            >
                                {[
                                    { label: "Bank Reference Number (BRN):", value: paymentData?.BRN },
                                    // { label: "Status Code (STC):", value: paymentData?.STC },
                                    { label: "Remarks (RMK):", value: paymentData?.RMK },
                                    { label: "Transaction Reference Number (TRN):", value: paymentData?.TRN },
                                    { label: "Transaction Time (TET):", value: paymentData?.TET },
                                    { label: "Payment Mode (PMD):", value: paymentData?.PMD },
                                    { label: "Reference ID (RID):", value: paymentData?.RID },
                                    // { label: "Version (VER):", value: paymentData?.VER },
                                    // { label: "Client ID (CID):", value: paymentData?.CID },
                                    // { label: "Type (TYP):", value: paymentData?.TYP },
                                    { label: "Client Reference Number (CRN):", value: paymentData?.CRN },
                                    { label: "Currency (CNY):", value: paymentData?.CNY },
                                    { label: "Amount (AMT):", value: paymentData?.AMT },
                                    // { label: "Checksum (CKS):", value: paymentData?.CKS }
                                ].map((item, index) => (
                                    <React.Fragment key={index}>
                                        <div
                                            style={{
                                                fontWeight: "bold",
                                                color: "#333",
                                                fontSize: "14px",
                                                padding: "8px 0"
                                            }}
                                        >
                                            {item.label}
                                        </div>
                                        <div
                                            style={{
                                                color: "#555",
                                                fontSize: "14px",
                                                padding: "8px 0",
                                                borderBottom: "1px solid #eaeaea"
                                            }}
                                        >
                                            {item.value || "N/A"}
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>





                        {formData?.approvals?.accountant ? (
                            < ></>
                        ) : (
                            <button
                                style={{
                                    marginTop: "20px", // Adds 20px margin on top of the button
                                    padding: "10px 20px", // Optional: Adds padding for a better button appearance
                                    backgroundColor: "#4CAF50", // Optional: Button background color
                                    color: "white", // Optional: Button text color
                                    border: "none", // Optional: Removes border for a cleaner look
                                    borderRadius: "5px", // Optional: Adds rounded corners
                                    cursor: "pointer" // Optional: Changes cursor to pointer on hover
                                }}
                                onClick={() => {
                                    approveCME();
                                }}
                            >
                                Approve Payment
                            </button>
                        )}


                        <div className="approvalStatusSection">
                            <h3 className="sectionTitle">Approval Status:</h3>
                            <div className="statusGrid">
                                <div className="statusRow">
                                    <strong>Clerk: </strong>
                                    {formData?.approvals?.clerk ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>
                                <div className="statusRow">
                                    <strong>Accountant: </strong>
                                    {formData?.approvals?.accountant ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>

                                <div className="statusRow">
                                    <strong>Registrar: </strong>
                                    {formData?.approvals?.registrar ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>
                                <div className="statusRow">
                                    <strong>President: </strong>
                                    {formData?.approvals?.president ? (
                                        <span className="statusApproved">approved</span>
                                    ) : (
                                        <span className="statusNotApproved">not yet approved</span>
                                    )}
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountantApprovalPage;
