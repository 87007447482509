import React, { useState } from 'react'
import '../Dashboard.css'
// import Nav from '../Nav/Nav'
import { useEffect } from 'react'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth';
import AdminNav from '../../Nav/AdminNav'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { endpoint, getformdata, Registrarapproval } from '../../../constants/constant';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const predefinedLabels = [
    'Registration Number of Association / Organization with evidence',
    'List of the Faculty with detailed qualification and experience',
    'Detailed programme schedule mentioning time, subject and the name of the Speaker',
    'List of Topics',
    'Medical Registration Certificate of the Organizing Chairman',
    'Medical Registration Certificate of the Organizing Secretary',
    'Any Other Supporting Documents'
];

const RegistrarApprovalPage = () => {
    //this page is used for form approvals
    const [showNewApprovals, setShowNewApprovals] = useState(true)
    const [showPrevApprovals, setShowPrevApprovals] = useState(false)
    const [Approvals, setApprovals] = useState([])
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const [formData, setformData] = useState({})
    const [token, settoken] = useState()
    // console.log(auth);
    const navigate = useNavigate();


    const { _id } = useParams()
    // var token='';

    useEffect(() => {
        console.log(auth);
        const tokendata = auth.RawToken;

        settoken(e => e = tokendata)



        const handlegetformdata = async () => {



            try {

                const response = await axios.get(`${endpoint}${getformdata}?id=${_id}`, {
                    headers: {
                        'Authorization': `Bearer ${tokendata}`,
                        'Accept': 'application/json'
                    }
                })
                console.log(response.data)
                const fd = response.data;
                // console.log(fd[0].chairman)
                setformData(e => fd[0])
                console.log(formData)
                // setApprovals(response.data)

            } catch (e) { console.log(e) }
        }



        handlegetformdata();
    }, [])


    const [viewNewApprovalentry, setviewNewApprovalentry] = useState(false);

    const openFormData = () => {
        setviewNewApprovalentry(e => !e)

    }

    const approveCME = async (e) => {

        console.log(token);
        // console.log(e.target.id);

        try {
            const response = await axios.post(
                `${endpoint}${Registrarapproval}`,
                { _id },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`, // Replace with your actual token

                    }
                }
            );
            console.log(response.data)

            setformData(response.data)
            // const fd = response.data;
            // // console.log(fd[0].chairman)
            // setformData(e => fd[0])
            // console.log(formData)
            // setApprovals(response.data)

        } catch (e) { console.log(e) }
    }





    return (
        <div className=''>
            <AdminNav />

            <div className='Dashboard'>

                <div className='mainBar approvalPage'>
                    <div className='dashMainHeading'>Registrar Dashboard</div>

                    <div className='dashHeadingFlex'>
                        <div className='dashHeading'>FORM DATA</div>
                        <div className='backBtn' onClick={() => navigate(-1)}>
                            <ArrowBackIcon />
                            Back to All Records
                        </div>
                    </div>



                    <div className='formDataDiv'>

                        <div className='formDatagrid'>
                            <div><strong>Organization Name: </strong>{formData?.organizationName}</div>
                            <div><strong>Email: </strong>{formData?.email}</div>
                            <div><strong>Address: </strong>{formData?.address}</div>
                            <div><strong>Theme: </strong>{formData?.theme}</div>
                            <div><strong>Registration Number: </strong>{formData?.registrationNumber}</div>
                            <div><strong>CME Start Date: </strong>{formData?.cmeStartDate}</div>
                            <div><strong>CME End Date: </strong>{formData?.cmeEndDate}</div>
                            <div><strong>No of Days: </strong>{formData?.daysDifference}</div>
                            <div><strong>Faculty 1: </strong>{formData?.faculty1}</div>
                            <div><strong>No of Delegates: </strong>{formData?.delegates}</div>
                            <div><strong>No of Exercise: </strong>{formData?.exercise}</div>
                            <div><strong>No of Hours: </strong>{formData?.hours}</div>
                            <div><strong>Chairman: </strong>{formData?.chairman}</div>
                            <div><strong>Secretary: </strong>{formData?.secretary}</div>
                            <div><strong>Methodology: </strong>{formData?.methodology}</div>
                            <div><strong>Permission Number: </strong>{formData?.permissionNumber}</div>
                            <div><strong>Permission Date: </strong>{formData?.permissionDate}</div>
                        </div>

                        <div className='pdfHead'>
                            List of Documents:
                        </div>

                        <div>
                            {predefinedLabels?.map((label, index) => {
                                // Check if the label from predefinedLabels matches the PDF_Label in formData.pdfURL
                                const matchingPDF = formData?.pdfURL?.find(pdf => pdf.PDF_Label === label);

                                return (
                                    <div key={index} className='viewPDFflex'>
                                        <div className='viewpdfSno'>
                                            {index + 1}
                                        </div>

                                        {/* Label display */}
                                        <div className='viewpdfLabel'>
                                            {label}
                                        </div>

                                        {/* View and Approve buttons if a match is found */}
                                        {matchingPDF && matchingPDF.url ? (
                                            <>
                                                {/* View button */}
                                                <a href={matchingPDF.url} className="viewPDFviewBtn" target="_blank" rel="noopener noreferrer">
                                                    <button>View</button>
                                                </a>

                                                {/* Approve button */}
                                                {!matchingPDF.approved ? (
                                                    <div className='viewPDFviewBtn'>
                                                        {/* <button
                                                        className='approveBtn'
                                                        id={matchingPDF.fileName}
                                                        // onClick={(e) => handleapprove(e)}
                                                    >
                                                        Approve
                                                    </button> */}
                                                    </div>
                                                ) : (
                                                    <div className='viewPDFviewBtn'>
                                                        <button
                                                            className='approveBtn approved'
                                                        >
                                                            Approved
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className='viewPDFviewBtn'></div>
                                                <div className='viewPDFviewBtn'></div>
                                            </>
                                        )}
                                    </div>
                                );
                            })}
                        </div>

                        {formData?.approvals?.registrar ?
                            <div className='approvedLabel' style={{ marginTop: '24px' }}>You have already Approved!</div>

                            :
                            <button
                                onClick={() => { approveCME() }} className='approveBtn' style={{ marginTop: '24px' }}
                            >
                                Approve CME
                            </button>
                        }





                        <div className='pdfHead'>
                            Approval Status:
                        </div>
                        <div>

                            <div className='StatusGrid'>
                                <strong>Clerk:</strong> {formData?.approvals?.clerk ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>
                            <div className='StatusGrid'>
                                <strong>Accountant:</strong> {formData?.approvals?.accountant ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>



                            <div className='StatusGrid'>
                                <strong>Registrar:</strong> {formData?.approvals?.registrar ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>

                            <div className='StatusGrid'>
                                <strong>President:</strong> {formData?.approvals?.president ?
                                    <span className='approvedLabel'>approved</span>
                                    : <span className='rejectedLabel'>not yet approved</span>}
                            </div>
                        </div>


                        {/* {formData?.approvals?.clerk ?

<button className='approveBtn' style={{ marginTop: '24px' }}
    onClick={generateCertificate}>
    Generate Certificate
</button>
:
null
} */}

                    </div>


                </div>
            </div>
        </div>
    )
}

export default RegistrarApprovalPage