export const endpoint = 'https://cme.ehssasdigitech.com/v1'
// export const endpoint = 'http://localhost:4700/v1'


export const getallforms = "/getallforms"
export const refresh = "/refresh"
export const getformdata = "/getformdata"
export const docapproval = "/docapproval"
export const docapprovalall = "/docapprovalall"
export const Presidentapproval = "/Presidentapproval"
export const Registrarapproval = "/Registrarapproval"
export const AccountantApproval = "/accountantapproval"
export const addformdata = "/addformdata"
export const signup = "/signup"
export const login = "/login"
export const logout = "/logout"
export const generateOtp = "/generateOTP"
export const verifyotp = "/verifyotp"
export const uploadcertificate = "/uploadcertificate"
export const testpayments = "/testpayment"



