import React, { useEffect } from 'react'
import './FormStyle.css';
import logo from '../../assets/hmcLogo.png';
import { useState } from 'react';
import axios from 'axios';
import Footer from '../Footer/Footer'
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { addformdata, endpoint, generateOtp, verifyotp } from '../../constants/constant';
import { styled } from '@mui/material/styles';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Button, CircularProgress, Typography } from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const Form = () => {

  const navigate = useNavigate();

  const [organizationName, setOrganizationName] = useState('');
  const [orgNameError, setOrgNameError] = useState('');
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [email, setEmail] = useState('');
  const [phone_number, setPhone_number] = useState("");
  const [emailError, setEmailError] = useState('');
  const [theme, setTheme] = useState('');
  const [themeError, setThemeError] = useState('');
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [venue, setVenue] = useState('');
  const [venueError, setVenueError] = useState('');
  const [cmeStartDate, setCmeStartDate] = useState('');
  const [cmeEndDate, setCmeEndDate] = useState('');
  const [daysDifference, setDaysDifference] = useState(0);
  // const [faculty, setFaculty] = useState('');
  const [delegates, setDelegates] = useState('');
  const [delegatesError, setDelegatesError] = useState('');
  const [scheduleFile, setScheduleFile] = useState(null);
  const [exercise, setExercise] = useState('');
  const [hours, setHours] = useState('');
  const [hoursError, setHoursError] = useState('');
  const [chairman, setChairman] = useState('');
  const [secretary, setSecretary] = useState('');
  const [methodology, setMethodology] = useState('');
  const [permissionNumber, setPermissionNumber] = useState('');
  const [permissionDate, setPermissionDate] = useState('');
  const [registrationFiles, setRegistrationFiles] = useState(null);
  const [supportingFiles, setSupportingFiles] = useState(null);
  const [facultyDocuments, setFacultyDocuments] = useState(null);
  const [topicDocuments, setTopicDocuments] = useState(null);
  const [chairmanCertificate, setChairmanCertificate] = useState(null);
  const [secretaryCertificate, setSecretaryCertificate] = useState(null);
  const [isInternationalFaculty, setIsInternationalFaculty] = useState('');
  const [isGovernmentOrganisation, setIsGovernmentOrganisation] = useState('');
  const [isCmeOffline, setIsCmeOffline] = useState(''); // Default to 'Offline'

  const [idFromDB, SetIdfromDB] = useState();
  const [otpInput, setOtpInput] = useState(false)
  const [otpvalue, setOtpvalue] = useState('')

  const [showModal, setShowModal] = useState(false);
  const [modalCallback, setModalCallback] = useState(null);

  //below states are for checking file size limits
  const [fileSizechecker, setFilesizechecker] = useState(true)
  const [uploading1, setUploading1] = useState(false); // To track upload status
  const [uploading2, setUploading2] = useState(false);
  const [uploading3, setUploading3] = useState(false);
  const [uploading4, setUploading4] = useState(false);
  const [uploading5, setUploading5] = useState(false);
  const [uploading6, setUploading6] = useState(false);
  const [uploading7, setUploading7] = useState(false);

  //below use states are for files
  const [PDF1, setPDF1] = useState(null)
  const [PDF2, setPDF2] = useState(null)
  const [PDF3, setPDF3] = useState(null)
  const [PDF4, setPDF4] = useState(null)
  const [PDF5, setPDF5] = useState(null)
  const [PDF6, setPDF6] = useState(null)
  const [PDF7, setPDF7] = useState(null)

  useEffect(() => {
    console.log(PDF1, PDF2, PDF2);



  }, [PDF2])


  // Helper functions
  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const difference = (end - start) / (1000 * 60 * 60 * 24);
    return Number(difference) + 1;
  };

  const handleDateChange = (e, setter, isStartDate) => {
    const newDate = e.target.value;
    setter(newDate);
    if (isStartDate && cmeEndDate) {
      setDaysDifference(calculateDaysDifference(newDate, cmeEndDate));
    } else if (!isStartDate && cmeStartDate) {
      setDaysDifference(calculateDaysDifference(cmeStartDate, newDate));
    }
  };

  const handleInputChange = (e, setter, regex, errorSetter, errorMessage) => {
    const value = e.target.value;
    if (value === '' || regex.test(value)) {
      setter(value);
      errorSetter('');
    } else {
      errorSetter(errorMessage);
    }
  };

  // Specific handlers
  const handleOrganizationNameChange = (e) =>
    handleInputChange(
      e,
      setOrganizationName,
      /^[a-zA-Z0-9\s,'.-]{1,50}$/,
      setOrgNameError,
      'Invalid organization name'
    );

  const handleAddressChange = (e) =>
    handleInputChange(
      e,
      setAddress,
      /^[a-zA-Z0-9\s,/.-]{1,50}$/,
      setAddressError,
      'Invalid address'
    );

  const handleThemeChange = (e) =>
    handleInputChange(
      e,
      setTheme,
      /^[a-zA-Z0-9\s,.]{1,20}$/,
      setThemeError,
      'The theme must be up to 20 words.'
    );

  const [isValid, setIsValid] = useState(true);

  const handleRegistrationNumChange = (e) => {
    const value = e.target.value;
    console.log(value);
    const RegNumRegex = /^[a-zA-Z0-9\s]{0,20}$/;
    if (RegNumRegex.test(value)) setRegistrationNumber(e => value)
  };


  const handleEmailChange = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const value = e.target.value;
    setEmail(value);
    setIsValid(emailRegex.test(value));
  };

  const handleNumberChange = (e) => {
    const value = e.target.value;
    const tenDigitRegex = /^\d{0,10}$/;
    if (tenDigitRegex.test(value)) {
      setPhone_number(value);
    } else {
      console.log("Please enter a valid 10-digit number.");
    }
  };

  const handleDelegatesChange = (e) => {
    const value = e.target.value;
    const numberRegex = /^\d+$/;
    if (value === '' || numberRegex.test(value)) {
      setDelegates(value);
      setDelegatesError('');
    } else {
      setDelegatesError('Please enter a valid number.');
    }
  };

  const handleHoursChange = (e) => {
    const value = e.target.value;
    const numberRegex = /^\d+$/;
    const daysDifference = calculateDaysDifference(cmeStartDate, cmeEndDate);

    if (value === '' || numberRegex.test(value)) {
      const totalHours = parseInt(value, 10);
      const maxAllowedHours = daysDifference * 4; // 4 hours per day

      if (totalHours > maxAllowedHours) {
        setHoursError(`Maximum allowed hours for ${daysDifference} day(s) is ${maxAllowedHours}.`);
      } else {
        setHours(value);
        setHoursError('');
      }
    } else {
      setHoursError('Please enter a valid number.');
    }
  };

  const handleVenueChange = (e) => {
    const value = e.target.value;
    const venueRegex = /^[a-zA-Z0-9\s,/.-]{0,50}$/;
    if (value === '' || venueRegex.test(value)) {
      setVenue(value);
      setVenueError('');
    } else {
      setVenueError('Invalid address. Only letters, numbers, spaces, commas, periods, and dashes are allowed.');
    }
  };


  useEffect(() => {


    console.log(daysDifference)
  }, [daysDifference])


  const handleSubmit = async (e) => {
    e.preventDefault();
    const requiredFields = [
      'organizationName', 'email', 'phone_number', 'address', 'theme',
      'registrationNumber', 'venue', 'cmeStartDate', 'cmeEndDate', 'delegates', 'exercise', //remove hours
      'chairman', 'secretary', "isInternationalFaculty", 'isGovernmentOrganisation', 'isCmeOffline'
    ];

    for (const field of requiredFields) {
      if (!eval(field)) {
        alert(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()} field.`);
        return; // Prevent form submission if any field is empty
      }
    }

    if (daysDifference <= 0) {
      alert("The end date cannot be earlier than the start date.");
      return;
    }

    if (isInternationalFaculty === "Yes") {
      if (!permissionNumber) {
        alert('Please fill in the Permission Number');
        return;
      }
      if (!permissionDate) {
        alert('Please fill in the Permission Date');
        return;
      }
    }

    const requiredFields2 = [
      'PDF1', 'PDF2', 'PDF3', "PDF4", "PDF5", "PDF6"
    ];

    for (const field of requiredFields2) {
      if (!eval(field)) {
        alert(`Please upload required documents.`);
        return;
      }
    }

    const currentDate = new Date();
    const fromDate = new Date(cmeStartDate); // Convert the string to a Date object
    console.log(currentDate, fromDate);

    const differenceInDays = Math.ceil((fromDate - currentDate) / (1000 * 60 * 60 * 24)); // Calculate difference in days
    console.log(differenceInDays);

    if (fromDate < currentDate) {
      alert("The CME start date cannot be in the past. Please select a valid date.");
      return; // Prevent further actions
    }


    if (differenceInDays < 30) {
      const confirmSubmission = window.confirm(
        "As per the CME Guidlines, you have to apply 30 days from the current date of submission. The Haryana Medical Council will not be liable for any refunds if the application for the CME scheduled on the selected date is not approved. Do you still wish to proceed?"
      );

      if (!confirmSubmission) {
        // If the user clicks "Cancel," stop the submission.
        return;
      }
    }




    const formData = new FormData();

    // List of state keys to include in form data
    const stateKeys = [
      'organizationName', 'email', 'phone_number', 'address', 'theme',
      'registrationNumber', 'venue', 'cmeStartDate', 'cmeEndDate',
      'daysDifference', 'delegates', 'exercise', 
      'chairman', 'secretary', 'methodology', 'permissionNumber',
      'permissionDate', "isInternationalFaculty", 'isGovernmentOrganisation', 'isCmeOffline'
    ];

    // Dynamically append state values to formData
    stateKeys.forEach((key) => {
      if (eval(key) !== undefined && eval(key) !== null) {
        formData.append(key, eval(key));
      }
    });

    // Append file-related states to formData
    const fileKeys = [
      { key: 'PDF1', label: 'Registration Number of Association / Organization with evidence' },
      { key: 'PDF2', label: 'List of the Faculty with detailed qualification and experience' },
      { key: 'PDF3', label: 'Detailed programme schedule mentioning time, subject and the name of the Speaker' },
      { key: 'PDF4', label: 'List of Topics' },
      { key: 'PDF5', label: 'Medical Registration Certificate of the Organizing Chairman' },
      { key: 'PDF6', label: 'Medical Registration Certificate of the Organizing Secretary' },
      { key: 'PDF7', label: 'Any Other Supporting Documents' },
    ];

    fileKeys.forEach(({ key, label }) => {
      if (eval(key) !== undefined && eval(key) !== null) {
        formData.append(label, eval(key));
      }
    });


    // console.log('Form Data Submitted:', formData);

    try {
      const formdata = await axios.post(`${endpoint}${addformdata}`, formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      )
      //form data has been sumbitted successfully if formdata.data.submitted = true;
      console.log(formdata)

      if (formdata.data.submitted) {
        setOtpInput(e => !e);
        SetIdfromDB(formdata.data.id);
        const requestOtp = await axios.post(`${endpoint}${generateOtp}`, { email })
        //if requestOtp.data = true; that means otp has been genereated and sent to mail 
      }
    }
    catch (e) { console.log(e) }
  };


  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(`${endpoint}${verifyotp}`, {
        email, otpvalue, idFromDB
      })
      const payload = response.data;
      console.log(payload)
      if (payload) {
        // const payload = response.data;
        console.log('inside payload fn')

        const paymentUrl = `https://haryanamedicalcouncil.org/Home/cmeRegistration?string=${payload}`;

        console.log(paymentUrl)

        window.location.href = paymentUrl;
      }




    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("An error occurred while verifying OTP.");
    }
  }

  const TestAutoPrefill = (e) => {

    e.preventDefault();
    setOrganizationName('Indian Medical Association');
    setEmail('ima@yopmail.com');
    setPhone_number('9876543210');
    setAddress('123 Nehru Road, New Delhi, India');
    setTheme('Advancing Healthcare 2025');
    setRegistrationNumber('3452025000');
    setVenue('Taj Palace, New Delhi');
    setCmeStartDate('2025-01-15');
    setCmeEndDate('2025-01-18');
    setDaysDifference(4)
    setIsInternationalFaculty("No");
    setDelegates(50);
    setExercise('Interactive Panel Discussion');
    // setHours(6);
    setChairman('Dr. Arvind Kumar');
    setSecretary('Dr. Priya Sharma');
    // setMethodology('Workshops, Lectures, and Case Studies');
    setIsGovernmentOrganisation("No");
    setIsCmeOffline("Offline")
    console.log(PDF5)
    if (PDF5) {
      console.log(PDF5)
      setPDF1(PDF5);
      setPDF2(PDF5);
      setPDF4(PDF5);
      setPDF3(PDF5);
      setPDF6(PDF5);
      setPDF7(PDF5);
    }


  }


  return (
    <>

      <div className='formPage'>
        <div className='form-header'>
          <img src={logo} className='logo' />
          <div>CME Portal</div>
        </div>

        <div className='formPageContainer'>
          {!otpInput ? <div className='formSubHead'>
            Online Registration of the CME / Workshop / Seminar / Conference / Training programme
          </div> : <></>}

          {!otpInput ?
            <div className="form-container">
              <form onSubmit={handleSubmit}>

                <div className="form-row">
                  <label>Name of the Conducting Organization</label>
                  <input
                    type="text"
                    value={organizationName}
                    maxLength={50}
                    onChange={handleOrganizationNameChange}
                  />
                </div>

                <div className="formWrap">
                  <div className="form-row">
                    <label>Name of the Organizing Chairman</label>
                    <input
                      type="text"
                      value={chairman}
                      onChange={(e) => setChairman(e.target.value)}
                    />
                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}></div>
                  </div>

                  <div className="form-row">
                    <label className="file-upload-label">
                      Medical Registration Certificate of the Organizing Chairman
                    </label>
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload File
                      <input
                        type="file"
                        accept=".pdf,application/pdf,.jpeg,.jpg,image/jpeg"
                        hidden
                        onChange={(event) => {
                          const file = event.target.files[0];
                          console.log(file);
                          if (file) {
                            if (file.size < 1000000) {
                              setUploading5(true);
                              setPDF5(null);
                              setTimeout(() => {
                                setPDF5(file);
                                setUploading5(false);
                              }, 2000); // Simulated delay for upload
                            } else {
                              setPDF5(null);
                              alert("File size is too large. Please select a file smaller than 1MB.");
                              console.log("Size too big, select a smaller file");
                            }
                          }
                        }}
                      />
                    </Button>

                    {/* Reserved space for status message */}
                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                      {uploading5 && <CircularProgress size={24} />}
                      {PDF5 && !uploading5 ? (
                        <Typography variant="body2" color="success.main">
                          File "{PDF5.name}" uploaded successfully!
                        </Typography>
                      ) : (
                        <Typography variant="body2" style={{ fontSize: "12px", color: "red" }}>
                          Only PDF, JPG, JPEG allowed. File size must be smaller than 1MB.
                        </Typography>
                      )}
                    </div>

                  </div>

                  <div className="form-row">
                    <label>Name of the Organizing Secretary</label>
                    <input
                      type="text"
                      value={secretary}
                      onChange={(e) => setSecretary(e.target.value)}
                    />
                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}></div>
                  </div>

                  <div className="form-row">
                    <label>Medical Registration Certificate of the Organizing Secretary</label>
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                    >
                      {PDF6 && !uploading6 ? <>Reupload File</> : <>Upload File</>}
                      <input
                        type="file"
                        accept=".pdf,application/pdf,.jpeg,.jpg,image/jpeg"
                        hidden
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (file.size < 3000000) {
                              setUploading6(true);
                              setPDF6(null);
                              setTimeout(() => {
                                setPDF6(file);
                                setUploading6(false);
                              }, 2000); // Simulated delay for upload
                            } else {
                              alert("File size is too large. Please select a file smaller than 1MB.");
                              setPDF6(null);
                              console.log("Size too big, select a smaller file");
                            }
                          }
                        }}
                      />
                    </Button>

                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                      {uploading6 && <CircularProgress size={24} />}
                      {PDF6 && !uploading6 ? (
                        <Typography variant="body2" color="success.main">
                          File "{PDF6.name}" uploaded successfully!
                        </Typography>
                      ) : (
                        <Typography variant="body2" style={{ fontSize: "12px", color: "red" }}>
                          Only PDF, JPG, JPEG allowed. File size must be smaller than 1MB.
                        </Typography>
                      )}
                    </div>
                  </div>
                </div>

                <div className="formWrap3">
                  <div className="form-row">
                    <label>Email</label>
                    <input
                      type="text"
                      value={email}
                      onChange={handleEmailChange}
                    />
                    {!isValid && <span className="error">Invalid email address</span>}
                  </div>

                  <div className="form-row">
                    <label>Phone Number</label>
                    <input
                      type="number"
                      value={phone_number}
                      onChange={handleNumberChange}
                    />
                    {addressError && <span className="error">{addressError}</span>}
                  </div>

                  <div className="form-row">
                    <label>Theme of CME</label>
                    <input
                      type="text"
                      value={theme}
                      onChange={handleThemeChange}
                    />
                    {themeError && <span className="error">{themeError}</span>}
                  </div>
                </div>

                <div className="form-row">
                  <label>Address of Organisation</label>
                  <input
                    type="text"
                    value={address}
                    onChange={handleAddressChange}
                  />
                  {addressError && <span className="error">{addressError}</span>}
                </div>

                <div className='formWrap'>
                  <div className="form-row">
                    <label>Date of CME </label>
                    <div className='date_input_div'>
                      <div className='date_input_div_inner'>
                        <label>From</label>
                        <input
                          className='date_box'
                          type="date"
                          value={cmeStartDate}
                          onChange={(e) => handleDateChange(e, setCmeStartDate, true)}
                        />
                      </div>

                      <div className='date_input_div_inner'>
                        <label>To</label>
                        <input
                          className='date_box'
                          type="date"
                          value={cmeEndDate}
                          onChange={(e) => handleDateChange(e, setCmeEndDate, false)}
                        />
                      </div>
                    </div>

                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                      {cmeStartDate && cmeEndDate && daysDifference <= 0 ?
                        <div className='error'>
                          To date can't be earlier than From date
                        </div>
                        : <>Number of days: {daysDifference}
                        </>
                      }
                    </div>
                    {/* <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}></div> */}
                  </div>

                  <div className="form-row">
                    <label>Registration Number of Association / Organization</label>
                    <div style={{ minHeight: '15px' }}></div>
                    <div className="dual_column_input">
                      <input
                        type="text"
                        value={registrationNumber}
                        onChange={handleRegistrationNumChange}
                        style={{ minHeight: '17.54px' }}
                      />
                      <Button
                        component="label"
                        variant="contained"
                        color="primary"
                        startIcon={<CloudUploadIcon />}
                      >
                        {PDF1 && !uploading1 ? <>Reupload File</> : <>Upload File</>}
                        <input
                          type="file"
                          accept=".pdf,application/pdf,.jpeg,.jpg,image/jpeg"
                          hidden
                          onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                              if (file.size < 1000000) {
                                setUploading1(true); // Start spinner for this upload
                                setPDF1(null); // Reset state for reupload
                                setTimeout(() => {
                                  setPDF1(file); // Save file after simulated delay
                                  setUploading1(false); // Stop spinner
                                }, 2000); // Simulated upload delay
                              } else {
                                alert("File size is too large. Please select a file smaller than 1MB.");
                                setPDF1(null); // Clear state if file is too big
                                console.log("Size too big, select a smaller file");
                              }
                            }
                          }}
                        />
                      </Button>
                    </div>

                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                      {uploading1 && <CircularProgress size={24} />}
                      {PDF1 && !uploading1 ? (
                        <Typography variant="body2" color="success.main">
                          File "{PDF1.name}" uploaded successfully!
                        </Typography>
                      ) :
                        (<Typography variant="body2" style={{ fontSize: '12px', color: 'red' }}>
                          Only PDF, JPG, JPEG allowed, File size must be smaller than 1MB
                        </Typography>)
                      }
                    </div>
                  </div>


                </div>

                <div className='formWrap3' style={{ margin: '20px 0 0px' }}>
                  {/* <div className='formWrap'> */}
                  <div className="form-row">
                    <label> Venue of CME</label>
                    <input
                      type="text" placeholder='Venue'
                      value={venue}
                      onChange={handleVenueChange}
                    />
                    {venueError && <span className="error">{venueError}</span>}

                  </div>

                  <div className="form-row">
                    <label>Expected number of attending delegates</label>
                    <input
                      type="number"
                      value={delegates}
                      onChange={handleDelegatesChange}
                    />
                    {delegatesError && <span className="error">{delegatesError}</span>}
                  </div>

                  {/* <div className="form-row">
                    <label>Expected No. of Hours</label>
                    <input
                      type="number"
                      value={hours}
                      onChange={handleHoursChange}
                    />
                    {hoursError && <span className="error">{hoursError}</span>}
                  </div> */}

                </div>

                <div className="formWrap">
                  <div className="form-row">
                    <label>List of the Faculty with detailed qualification and experience.</label>
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                    >
                      {PDF2 && !uploading2 ? <>Reupload File</> : <>Upload File</>}
                      <input
                        type="file"
                        accept=".pdf,application/pdf,.xls,.xlsx,.doc,.docx,.jpg,image/jpeg"
                        hidden
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (file.size < 3000000) {
                              setUploading2(true); // Start spinner for this upload
                              setPDF2(null); // Reset state for reupload
                              setTimeout(() => {
                                setPDF2(file); // Save file after simulated delay
                                setUploading2(false); // Stop spinner
                              }, 2000); // Simulated upload delay
                            } else {
                              alert("File size is too large. Please select a file smaller than 3MB.");
                              setPDF2(null); // Clear state if file is too big
                              console.log("Size too big, select a smaller file");
                            }
                          }
                        }}
                      />
                    </Button>

                    {/* Reserved space for spinner and message */}
                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                      {uploading2 && <CircularProgress size={24} />}
                      {PDF2 && !uploading2 ? (
                        <Typography variant="body2" color="success.main">
                          File "{PDF2.name}" uploaded successfully!
                        </Typography>
                      ) : (<Typography variant="body2" style={{ fontSize: '12px', color: 'red' }}>
                        Only PDF, XLS, DOC allowed, File Should be less than 3 MB
                      </Typography>)}
                    </div>
                  </div>



                  <div className="form-row">
                    <label>List of Topics</label>
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                    >
                      {PDF4 && !uploading4 ? (
                        <>upload again</>
                      ) : (
                        <>upload</>
                      )}
                      <input
                        type="file"
                        accept=".pdf,.xls,.xlsx,.doc,.docx"
                        hidden
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (file.size < 3000000) {
                              setUploading4(true); // Start spinner
                              setPDF4(null);
                              // Simulate upload process
                              setTimeout(() => {
                                setPDF4(file); // Save file
                                setUploading4(false); // Stop spinner
                              }, 2000); // Simulated delay of 2 seconds
                            } else {
                              alert("File size is too large. Please select a file smaller than 3MB.");
                              console.log("Size too big, select a smaller file");

                            }
                          }
                        }}
                      />
                    </Button>
                    <div style={{ minHeight: '24px', display: 'flex', alignItems: 'center' }}>
                      {uploading4 && !PDF4 && <CircularProgress size={24} />}
                      {PDF4 && !uploading4 ? (
                        <Typography variant="body2" color="success.main">
                          File "{PDF4.name}" uploaded successfully!
                        </Typography>
                      ) : (<Typography variant="body2" style={{ fontSize: '12px', color: 'red' }}>
                        Only PDF, XLS, DOC allowed, File Should be less than 3 MB
                      </Typography>
                      )}
                    </div>
                  </div>
                </div>



                <div className='formWrap'>
                  <div className="form-row">
                    <label>Exercises to be covered </label>
                    <input
                      type="text"
                      value={exercise}
                      onChange={(e) => setExercise(e.target.value)}
                    />
                    <div style={{ minHeight: "4px", display: "flex", alignItems: "center" }}></div>
                  </div>

                  <div className="form-row">
                    <label>
                      Detailed programme schedule mentioning time, subject, and the name of the Speaker
                    </label>
                    <Button
                      component="label"
                      variant="contained"
                      color="primary"
                      startIcon={<CloudUploadIcon />}
                    >
                      {PDF3 && !uploading3 ? <>Reupload File</> : <>Upload File</>}
                      <input
                        type="file"
                        accept=".pdf,application/pdf,.jpeg,.jpg,image/jpeg"
                        hidden
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if (file) {
                            if (file.size < 3000000) {
                              setUploading3(true); // Start spinner for this upload
                              setPDF3(null); // Reset state for reupload
                              setTimeout(() => {
                                setPDF3(file); // Save file after simulated delay
                                setUploading3(false); // Stop spinner
                              }, 2000); // Simulated upload delay
                            } else {
                              setPDF3(null); // Clear state if file is too big
                              console.log("Size too big, select a smaller file");
                              alert("File size is too large. Please select a file smaller than 1MB.");
                            }
                          }
                        }}
                      />
                    </Button>

                    {/* Reserved space for spinner and message */}
                    <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                      {uploading3 && <CircularProgress size={24} />}
                      {PDF3 && !uploading3 ? (
                        <Typography variant="body2" color="success.main">
                          File "{PDF3.name}" uploaded successfully!
                        </Typography>
                      ) : (<Typography variant="body2" style={{ fontSize: '12px', color: 'red' }}>
                        Only PDF, JPG, JPEG allowed, File size must be smaller than 1MB
                      </Typography>)}
                    </div>
                  </div>


                </div>






                {/* <div className="form-row">
                  <label>Methodology to be adopted by the Organization viz. Lecture / Workshop / Hands On Training / Discussion / Audio- Visual Presentation etc.</label>
                  <textarea
                    value={methodology}
                    onChange={(e) => setMethodology(e.target.value)}
                  />
                </div> */}

                <div className='form-row'>
                  <label>Is there any International Faculty Involved?</label>
                  <div className='form-flex'>
                    <input
                      type="radio"
                      name='isInternationalFaculty'
                      id='yes'
                      value="Yes"
                      checked={isInternationalFaculty === 'Yes'}
                      onChange={(e) => setIsInternationalFaculty(e.target.value)}
                    />
                    <label htmlFor="yes" className='cursor-pointer'>Yes</label>

                    <input
                      type="radio"
                      name='isInternationalFaculty'
                      id='no'
                      value="No"
                      checked={isInternationalFaculty === 'No'}
                      onChange={(e) => setIsInternationalFaculty(e.target.value)}
                    />
                    <label htmlFor="no" className='cursor-pointer'>No</label>
                  </div>
                </div>

                <div className='form-row'>
                  <label>CME conducted by?</label>
                  <div className='form-flex'>
                    <input
                      type="radio"
                      name='isGovernmentOrganisation'
                      id='government'
                      value="Yes"
                      checked={isGovernmentOrganisation === 'Yes'}
                      onChange={(e) => setIsGovernmentOrganisation(e.target.value)}
                    />
                    <label htmlFor="government" className='cursor-pointer'>Government Organization/ body</label>

                    <input
                      type="radio"
                      name='isGovernmentOrganisation'
                      id='private'
                      value="No"
                      checked={isGovernmentOrganisation === 'No'}
                      onChange={(e) => setIsGovernmentOrganisation(e.target.value)}
                    />
                    <label htmlFor="private" className='cursor-pointer'>Private Organization/ body</label>
                  </div>
                </div>

                <div className='form-row'>
                  <label>Is the CME conducted offline or online?</label>
                  <div className='form-flex'>
                    <input
                      type="radio"
                      name='isCmeOffline'
                      id='offline'
                      value="Offline"
                      checked={isCmeOffline === 'Offline'}
                      onChange={(e) => setIsCmeOffline(e.target.value)}
                    />
                    <label htmlFor="offline" className='cursor-pointer'>Offline</label>

                    <input
                      type="radio"
                      name='isCmeOffline'
                      id='online'
                      value="Online"
                      checked={isCmeOffline === 'Online'}
                      onChange={(e) => setIsCmeOffline(e.target.value)}
                    />
                    <label htmlFor="online" className='cursor-pointer'>Online</label>
                  </div>
                </div>

                {isInternationalFaculty === 'Yes' && (
                  <div className='formWrap'>
                    <div className="form-row">
                      <label>Prior Permission of MCI in case of Foreign faculties (if applicable)</label>
                      <input
                        type="text"
                        value={permissionNumber}
                        onChange={(e) => setPermissionNumber(e.target.value)}
                      />
                    </div>
                    <div className="form-row">
                      <label>Permission Date</label>
                      <input
                        type="date"
                        value={permissionDate}
                        onChange={(e) => setPermissionDate(e.target.value)}
                      />
                    </div>
                  </div>
                )}

                {/* <div className="form-row">
                  <label>If Any Other Supporting Documents</label>
                  <Button
                    component="label"
                    variant="contained"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                  >
                    {PDF7 && !uploading7 ? <>Reupload File</> : <>Upload File</>}
                    <input
                      type="file"
                      accept=".pdf,application/pdf,.jpeg,.jpg,image/jpeg,.xls,.xlsx,"
                      hidden
                      onChange={(e) => {
                        const file = e.target.files[0];
                        if (file) {
                          if (file.size < 3000000) {
                            setUploading7(true); 
                            setPDF7(null); 
                            setTimeout(() => {
                              setPDF7(file); 
                              setUploading7(false); 
                            }, 2000); 
                          } else {
                            setPDF7(null); 
                            console.log("Size too big, select a smaller file");
                            alert("File size is too large. Please select a file smaller than 1MB.");

                          }
                        }
                      }}
                    />
                  </Button>

               
                  <div style={{ minHeight: "24px", display: "flex", alignItems: "center" }}>
                    {uploading7 && <CircularProgress size={24} />}
                    {PDF7 && !uploading7 ? (
                      <Typography variant="body2" color="success.main">
                        File "{PDF7.name}" uploaded successfully!
                      </Typography>
                    ) : (<Typography variant="body2" style={{ fontSize: '12px', color: 'red' }}>
                      Only PDF, JPG, JPEG allowed, File size must be smaller than 1MB
                    </Typography>)}
                  </div>
                </div> */}

                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <button
                    className="test-button"
                    onClick={TestAutoPrefill}
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid white',
                      opacity: 0.1,
                      color: 'transparent',
                    }}
                  >
                    Auto-Prefill
                  </button>
                </div>

                <button type="submit"
                  className="submit-button">
                  Submit
                </button>

              </form>
            </div>
            :
            <div className="otpSection">
              <div className="otpMessage">OTP SENT TO EMAIL ID</div>
              <input
                type="number"
                value={otpvalue}
                onChange={(e) => { setOtpvalue(e.target.value) }}
                className="otpInput"
                placeholder="Enter OTP"
              />
              <button
                className="otpSubmitButton"
                onClick={() => handleVerifyOtp()}
              >
                Submit OTP and Proceed To Payment
              </button>
            </div>

          }
        </div>

      </div >

      <Footer />
    </>
  )
}

export default Form
