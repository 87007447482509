import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Ack.css";
import logo from '../../assets/HMC_logo.png';

const Ack = () => {
    const generatePdf = () => {
        const doc = new jsPDF();
        doc.setDrawColor(200, 0, 0);
        doc.setLineWidth(0.5);
        doc.rect(10, 10, 190, 277);
        doc.addImage(logo, 'PNG', 20, 15, 40, 35);
        doc.setTextColor(200, 0, 0);
        doc.setFont("times", "bold");
        doc.setFontSize(16);
        doc.text("Haryana Medical Council", 175, 20, { align: "right" });
        doc.setTextColor(0, 0, 0);
        doc.setFont("times", "bolditalic");
        doc.setFontSize(12);
        doc.text("Haryana Medical Council", 190, 25, { align: "right" });
        doc.text("SCO-410 2nd Floor, Sector-20, Panchkula-134116", 190, 30, { align: "right" });
        doc.text("Phone: 0172-4090410, 0172-2520165", 190, 35, { align: "right" });
        doc.text("Email: registrarhmc@gmail.com, info@haryanamedicalcouncil.org", 190, 41, { align: "right" });

        const tableData = [
            ["Acknowledgement Submitted On", "25-05-2022"],
            ["Doctor Name", "SHEFALI GUPTA"],
            ["Father's Name", "SANJAY SINGLA"],
            ["Date of Birth", "23-Nov-1996"],
            ["Mobile No.", "9958022923"],
            ["Case ID", "100175"],
            ["Request Details", "Permanent Registration (Graduate from Haryana)"],
            ["Mode of Payment", "Online"],
            ["Order No", "1653561939"],
            ["Amount Paid", "3100"],
            ["Transaction ID", "107481507"],
            ["Online Transaction Status", "Success"],
        ];

        doc.autoTable({
            startY: 60,
            body: tableData,
            styles: {
                halign: "left",
                valign: "middle",
                lineWidth: 0.1,
                lineColor: [0, 0, 0],
            },
            headStyles: {
                fillColor: [200, 0, 0],
                textColor: [255, 255, 255],
            },
            tableLineWidth: 0.1,
            tableLineColor: [0, 0, 0],
        });

        let finalY = doc.lastAutoTable.finalY + 10;
        doc.setFont("times", "normal");
        doc.text(
            "Remarks: Please mention the Case ID 100175 for any future correspondences with reference to this application or use it to check the status of your application online.",
            20,
            finalY,
            { maxWidth: 175 }
        );

        finalY += 20;
        doc.text(
            "Note: You are requested to submit hard copies (self-attested) of documents as per the checklist along with a copy of acknowledgment to Haryana Medical Council office within 90 days for processing your application. If documents are not received within 90 days, your application will be automatically rejected, and no claim for a refund of fees will be entertained.",
            20,
            finalY,
            { maxWidth: 175 }
        );

        finalY += 40;

        doc.setFontSize(16);
        doc.text("Document Checklist", 20, finalY);

        const checklist = [
            "Provisional Registration Certificate in original",
            "Self-attested copy of Internship Completion Certificate",
            "Self-attested copy of Aadhar Card / PAN Card / Passport / Driving License",
            "Demand draft in original as per HMC fees structure (if payment mode is offline)",
        ];

        doc.setFontSize(12);
        checklist.forEach((item, index) => {
            finalY += 10;
            doc.text(`${index + 1}. ${item}`, 20, finalY);
        });
        doc.save("HaryanaMedicalCouncil.pdf");
    };

    return (
        <div className="container">
            <h1 className="heading">PDF Generator</h1>
            <button onClick={generatePdf} className="button">
                Download PDF
            </button>
        </div>
    );
};

export default Ack;

