import React, { useState } from 'react'
import '.././Dashboard.css'
// import Nav from '../Nav/Nav'
import { useEffect } from 'react'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth';
import AdminNav from '../../Nav/AdminNav'
import { Link, useLocation } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import { endpoint, getallforms, uploadcertificate, getformdata } from '../../../constants/constant';
import logo from '../../../assets/HMC_logo.png';

const ClerkDash = () => {

    const [showNewApprovals, setShowNewApprovals] = useState(false)
    const [showPrevApprovals, setShowPrevApprovals] = useState(false)
    const [showCertificate, setShowCertificate] = useState(false)
    const [Approvals, setApprovals] = useState([])
    const { auth } = useAuth();
    const { setAuth } = useAuth();
    const location = useLocation();
    const [token, settoken] = useState()
    // console.log(auth);

    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const [showUploadCertiBtn, setshowUploadCertiBtn] = useState(false);

    // Handle file selection
    const handleFileChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setSelectedFile(event.target.files[0]);
        }
    };

    // Upload Certificate Function
    const uploadCertificate = async (id) => {
        if (!selectedFile) {
            setUploadStatus("Please select a PDF file before uploading.");
            return;
        }

        const formData = new FormData();
        formData.append("certificate", selectedFile); // Append the file
        formData.append("itemId", id); // Append the item ID

        try {

            const response = await axios.post(`${endpoint}${uploadcertificate}`, formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                }




            )


            if (response.ok) {
                setUploadStatus("Certificate uploaded successfully!");
                setSelectedFile(null); // Reset the file input
            } else {
                setUploadStatus("Failed to upload certificate.");
            }
        } catch (error) {
            console.error("Error uploading certificate:", error);
            setUploadStatus("An error occurred while uploading.");
        }



    };


    useEffect(() => {
        console.log(auth);
        const token = auth.RawToken;

        settoken(e => e = token)

        const getformdata = async () => {

            try {
                const response = await axios.get(`${endpoint}${getallforms}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Accept': 'application/json'
                    }
                })


                console.log(response.data)
                setApprovals(response.data)

            } catch (e) { console.log(e) }
        }



        getformdata();
    }, [])


    const [viewNewApprovalentry, setviewNewApprovalentry] = useState(false);

    const openFormData = () => {
        setviewNewApprovalentry(e => !e)

    }






    const generateCertificate = async (_id) => {
        let formdata;

        try {
            const response = await axios.get(`${endpoint}${getformdata}?id=${_id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json'
                }
            });
            formdata = await response?.data[0];
            console.log(formdata)
        } catch (e) {
            console.log(e);
        }

        // Extract data
        const ObserverName = formdata?.observerDetails?.name || 'N/A';
        const ObserverMobile = formdata?.observerDetails?.mobile || 'N/A';
        const ObserverDesignation = formdata?.observerDetails?.designation || 'N/A';
        const conferenceDate = formdata?.date || 'N/A';
        const location = formdata?.address || 'N/A';
        const theme = formdata?.theme?.toUpperCase() || 'N/A';
        const awardPoints = formdata?.PointsAwarded || 0;
        const secretary = formdata?.secretary || 'N/A';
        const honorarium = "Rs.5000/- per day";
        const taRate = "Rs.20 per KM";

        // Initialize jsPDF
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width;
        const marginX = 20;
        const lineSpacing = 8;

        // Add Logo
        const imgWidth = 30, imgHeight = 30;
        doc.addImage(logo, "PNG", marginX, 10, imgWidth, imgHeight);

        // Header Section
        doc.setFont("times", "bold");
        doc.setFontSize(16);
        doc.text("HARYANA MEDICAL COUNCIL", pageWidth / 2, 20, { align: "center" });

        doc.setFontSize(11);
        doc.setFont("times", "normal");
        doc.text("SCO-410, 2nd floor, Sector - 20, Panchkula, Haryana - 134116", pageWidth / 2, 28, { align: "center" });
        doc.text("Email - registrarhmc@gmail.com, Office - 0172 - 2520165", pageWidth / 2, 34, { align: "center" });

        // Horizontal Line
        doc.line(marginX, 42, pageWidth - marginX, 42);

        // Reference and Date
        doc.setFont("times", "bold");
        doc.setFontSize(12);
        doc.text("HMC/2024/", marginX, 50);
        doc.text("Dated:", pageWidth - 70, 50);

        // Recipient
        let currentY = 58;
        doc.setFont("times", "normal");
        doc.text("To,", marginX, currentY);
        doc.text(`                   ${secretary},`, marginX, currentY += lineSpacing * 0.8);
        doc.text(`                   Organizing Secretary,`, marginX, currentY += lineSpacing * 0.8);

        // Subject
        currentY += lineSpacing * 1.4;
        doc.setFont("times", "bold");
        doc.text("Subject:", marginX, currentY);
        doc.setFont("times", "bold");
        doc.text(`Regarding Grant of Credit Hour's for the Conference/CME to be held on `, marginX + 20, currentY);

        doc.text(`${conferenceDate} at ${location}.`, marginX + 20, currentY + 6);

        // Main Body
        currentY += lineSpacing * 2;
        doc.setFont("times", "normal");



        doc.text(`                   With reference to your application cited above, it is to inform you that the Haryana`, marginX, currentY);
        currentY += 5;

        const firstPara = `Medical Council has considered your proposal and grant to award ${awardPoints} Credit Hour(s) for the CME/Workshop/Conference/Seminar/Training for "${theme}" to be held on ${conferenceDate} at ${location}.`;
        const firstParaLines = doc.splitTextToSize(firstPara, pageWidth - marginX * 2);

        doc.text(
            firstParaLines,
            marginX,
            currentY
        );

        currentY += lineSpacing * 2;
        doc.setFont("times", "bold");

        const boldNote = `                   The Haryana Medical Council appoints ${ObserverName}, ${ObserverDesignation} as the Observer. "The Observer shall be provided a place on the Dias during the inauguration and his/her Honorarium (${honorarium}), T.A. (${taRate} for own vehicle or as per actual taxi bill) and Accommodation shall be met by your organization/association. Observer shall be one of the signatories of the CME/ Workshop/ Conference/ Seminar/ Training Certificate. However, the accreditation granted shall be liable to be terminated at any time, if the information furnished by the association/ organization is found false or the Haryana Medical Council Appointed Observer finds any discrepancy or violation of guidelines. The association/ organization could be debarred from future accreditation in such a case."`;
        const boldLines = doc.splitTextToSize(boldNote, pageWidth - marginX * 2);
        doc.text(
            boldLines,
            marginX,
            currentY
        );

        currentY += lineSpacing * 5.8;
        const observerNote = `                   The Organizer shall submit the CME Observer Feedback Form (Annexure-III) to the undersigned immediately after completion of the programme along with name of all delegates & faculties/Speakers with their MCI/ State Medical Council Registration Number, telephone number, address and subjects covered by Faculties/ Speaker with duration for record of this office which is mandatory.`;
        const observerLines = doc.splitTextToSize(observerNote, pageWidth - marginX * 2);

        doc.setFont("times", "normal");
        doc.text(observerLines, marginX, currentY);



        doc.addImage(logo, "PNG", marginX, currentY + 30, imgWidth, imgHeight);

        // Footer
        // currentY = doc.internal.pageSize.height - 100;
        currentY += lineSpacing * 4.6;
        doc.text("With Regards,", pageWidth - 70, currentY);
        doc.text("Registrar,", pageWidth - 70, currentY += lineSpacing * 2);
        doc.text("Haryana Medical Council", pageWidth - 70, currentY += lineSpacing * 0.8);

        // Copy Forwarded
        currentY += lineSpacing * 1.6;
        doc.setFont("times", "bold");
        doc.text("HMC/2024/", marginX, currentY);
        doc.text("Dated:", pageWidth - 70, currentY);
        doc.setFont("times", "normal");

        const endNote = `                   Copy is forwarded to ${ObserverName}, ${ObserverDesignation} for information & further necessary action please. (MOBILE - ${ObserverMobile})`;
        const endLines = doc.splitTextToSize(endNote, pageWidth - marginX * 2);
        doc.text(
            endLines,
            marginX,
            currentY += lineSpacing * 1.6
        );

        // Save PDF
        doc.save("certificate.pdf");
    };






    const click = () => {
        console.log('clicked')
    }

    useEffect(() => {
        setShowNewApprovals(false);
        setShowPrevApprovals(false);
        setShowCertificate(false);
        // Extract the path after "/dashboard/"
        const pathAfterDashboard = location.pathname.split("/dashboard/")[1];

        if (pathAfterDashboard === "new") {
            setShowNewApprovals(true);
        } else if (pathAfterDashboard === "previous") {
            setShowPrevApprovals(true);
        } else if (pathAfterDashboard === 'certificategeneration') {
            setShowCertificate(true);
        }
    }, [location.pathname]);
   
    const [searchCaseId, setSearchCaseId] = useState("");


    // // Filter approvals based on search input and clerk's status
    // const filteredApprovals = Approvals.filter(
    //     (item) =>
    //         !item.approvals.clerk &&
    //         item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())


    // );



    return (
        <div className=''>
            <AdminNav />

            <div className='Dashboard'>
                {/* <div className='sideBar'>
                    <div className={showNewApprovals ? 'selected sidebarItem' : 'sidebarItem'}
                        // onClick={() => { click()}}>
                        onClick={() => { setShowCertificate(false); setShowNewApprovals(true); setShowPrevApprovals(false) }}>
                        New Approvals
                    </div>
                    <div className={showPrevApprovals ? 'selected sidebarItem' : 'sidebarItem'}
                        onClick={() => { setShowCertificate(false); setShowPrevApprovals(true); setShowNewApprovals(false) }}>
                        Previous Approvals
                    </div>
                    <div className={showCertificate ? 'selected sidebarItem' : 'sidebarItem'}
                        onClick={() => { setShowCertificate(true); setShowNewApprovals(false); setShowPrevApprovals(false); }}>
                        Certificate Generation
                    </div>
                </div> */}
                <div className="sideBar">
                    <Link
                        to={{
                            pathname: "/dashboard/new",
                            state: { from: location.pathname },
                        }}
                        className={location.pathname === "/dashboard/new" ? "selected sidebarItem" : "sidebarItem"}
                    >
                        New Applications
                    </Link>

                    <Link
                        to={{
                            pathname: "/dashboard/previous",
                            state: { from: location.pathname },
                        }}
                        className={location.pathname === "/dashboard/previous" ? "selected sidebarItem" : "sidebarItem"}
                    >
                        Previously Approved
                    </Link>

                    <Link
                        to={{
                            pathname: "/dashboard/certificategeneration",
                            state: { from: location.pathname },
                        }}
                        className={
                            location.pathname === "/dashboard/certificategeneration"
                                ? "selected sidebarItem"
                                : "sidebarItem"
                        }
                    >
                        Certificate Generation
                    </Link>
                </div>


                <div className='mainBar'>
                    <div className='dashMainHeading'>Clerk Dashboard</div>

                    <div className="searchBox">
                        <input
                            type="text"
                            placeholder="Search by Case ID..."
                            value={searchCaseId}
                            onChange={(e) => setSearchCaseId(e.target.value)}
                            className="searchInput"
                        />
                    </div>
                    {/* {!viewNewApprovalentry?         */}
                    {showNewApprovals ?
                        <>
                            <div className='dashHeading'>New Applications</div>

                            <div className='approvalsContainer'>

                                {Approvals && Approvals.length > 0 ?
                                    (Approvals
                                        .filter((item) => item.approvals.clerk === false &&
                                        item.CRN.toLowerCase().includes(searchCaseId.toLowerCase())
                                    ).map((item, i) => (
                                            <div key={i} className='approval'>
                                                <div className='approvalContent'>
                                                    <div><strong>Organization Name:</strong> {item.organizationName}</div>
                                                    <div>
                                                        <strong>Case ID:</strong> {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                    </div>
                                                    <div><strong>Theme:</strong> {item.theme}</div>
                                                    <div><strong>Dates:</strong> {item.cmeStartDate} to {item.cmeEndDate}</div>
                                                </div>

                                                <Link to={`/dashboard/clerk/${item._id}`} key={item._id}>
                                                    <button>
                                                        View
                                                    </button>
                                                </Link>

                                            </div>
                                        ))
                                    )
                                    :
                                    <div>No records available</div>
                                }

                            </div>

                        </>

                        :
                        null
                    }

                    {showPrevApprovals ?
                        <>
                            <div className='dashHeading'>Previous Approvals</div>

                            <div className='approvalsContainer'>

                                {Approvals && Approvals.length > 0 ? (
                                    Approvals.filter(
                                        (item) =>
                                            item.approvals.clerk === true &&
                                            item.CRN.toLowerCase().includes(searchCaseId.toLowerCase()) // Filtering by Case ID
                                    ).map((item, i) => (
                                        <div key={i} className="approval">
                                            <div className="approvalContent">
                                                <div>
                                                    <strong>Organization Name:</strong> {item.organizationName}
                                                </div>
                                                <div>
                                                    <strong>Case ID:</strong>{" "}
                                                    {item.CRN.replace(/^(HMC)(CME)/, "$1/$2/")}
                                                </div>
                                                <div>
                                                    <strong>Theme:</strong> {item.theme}
                                                </div>
                                                <div>
                                                    <strong>Dates:</strong> {item.cmeStartDate} to{" "}
                                                    {item.cmeEndDate}
                                                </div>
                                            </div>

                                            <Link to={`/dashboard/clerk/${item._id}`} key={item._id}>
                                                <button>View</button>
                                            </Link>
                                        </div>
                                    ))
                                ) : (
                                    <div>No records available</div>
                                )}


                            </div>
                        </>
                        : null
                    }

                    {showCertificate ? (
                        <>
                            <div className="dashHeading">Pending Certificates</div>

                           

                            <div className="approvalsContainer">
                                {Approvals && Approvals.length > 0 ? (
                                    Approvals.filter(
                                        (item) =>
                                            item.approvals.clerk === true &&
                                            item.approvals.registrar === true &&
                                            item.approvals.accountant === true &&
                                            item.approvals.president === true &&
                                            item.CRN.toLowerCase().includes(searchCaseId.toLowerCase()) // Filtering by Case ID
                                    ).map((item, i) => (
                                        <div key={i} className="approval">
                                            <div className="approvalContent">
                                                <div>
                                                    <strong>Organization Name:</strong>{" "}
                                                    {item.organizationName}
                                                </div>
                                                <div>
                                                    <strong>Case ID:</strong> {item.CRN}
                                                </div>
                                                <div>
                                                    <strong>Theme:</strong> {item.theme}
                                                </div>
                                                <div>
                                                    <strong>Dates:</strong> {item.cmeStartDate} to{" "}
                                                    {item.cmeEndDate}
                                                </div>
                                            </div>

                                            <button
                                                onClick={() => generateCertificate(item._id)}
                                                className="approveBtn"
                                            >
                                                Generate Certificate
                                            </button>

                                            {showUploadCertiBtn && (
                                                <div className="ShowUploadCertiModalContainer">
                                                    <div className="ShowUploadCertiModal">
                                                        <div>
                                                            <div
                                                                className="closeShowUploadCertiModal"
                                                                onClick={() =>
                                                                    setshowUploadCertiBtn(false)
                                                                }
                                                            >
                                                                ×
                                                            </div>
                                                            <div className="ShowUploadCertiHead">
                                                                Upload Certificate
                                                            </div>
                                                        </div>
                                                        <input
                                                            type="file"
                                                            accept="application/pdf"
                                                            onChange={handleFileChange}
                                                            className="uploadCertiInput"
                                                        />
                                                        <button
                                                            onClick={() =>
                                                                uploadCertificate(item._id)
                                                            }
                                                            disabled={!selectedFile}
                                                            className="approveBtn"
                                                        >
                                                            Upload Certificate
                                                        </button>
                                                        {uploadStatus && <p>{uploadStatus}</p>}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ) : (
                                    <div>No records available</div>
                                )}
                            </div>
                        </>
                    ) : null}


                    {/* <div>
                        <button onClick={()=>{setviewNewApprovalentry(e=>!e)}}>back</button>
                    
                    </div>}  */}


                </div>
            </div>
        </div>
    )
}

export default ClerkDash