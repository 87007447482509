import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ViewStatus.css';
import Nav from '../Nav/Nav';
import Footer from '../Footer/Footer';
import axios from 'axios';

import jsPDF from "jspdf";
import "jspdf-autotable";
import "./Ack.css";
import logo from '../../assets/HMC_logo.png';
import { endpoint } from '../../constants/constant';

const ViewStatus = () => {
    const [statusData, setStatusData] = useState(null);
    const [error, setError] = useState(null);
    const [isIdValid, setIsIdValid] = useState(false);
    const [showOtpPage, setShowOtpPage] = useState(false);
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false);
    const [caseDetails, setCaseDetails] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const undecodedId = searchParams.get('id'); // Extract the 'id' query parameter
    const id = decodeURIComponent(undecodedId);


    useEffect(() => {
        if (!id) {
            setError('No ID provided in the URL.');
            return;
        }

        const fetchStatusData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${endpoint}/getregistrationstatus?id=${id}`);
                if (!response.data) {
                    setTimeout(() => navigate(-1), 2000);
                    setError('ID not found. Redirecting to the previous page...');
                } else {
                    setIsIdValid(true);
                    setShowOtpPage(true); // Show OTP page
                }
            } catch (err) {
                setError('Failed to fetch status data.');
            } finally {
                setLoading(false);
            }
        };

        fetchStatusData();
    }, [id, navigate]);

    const handleOtpSubmit = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${endpoint}/submitstatusotp`, { undecodedId, otp });
            const respStatus = response.data;

            if (respStatus.success) {
                setError(null);
                // Hide OTP page
                setShowOtpPage(false);

                // Directly map the data and update the page
                let {_id,
                    organizationName,
                    email,
                    phone_number,
                    address,
                    theme,
                    registrationNumber,
                    cmeStartDate,
                    cmeEndDate,
                    daysDifference,
                    delegates,
                    exercise,
                    hours,
                    chairman,
                    secretary,
                    methodology,
                    pdfURL,
                    approvals,
                    alldocsverified,
                    time,
                    date,
                    certificateGenerated,
                    CRN,
                    isGovernmentOrganisation,
                    emailVerified,
                    uploadedCertificateURL,
                    isInternationalFaculty,
                    isCmeOffline,
                    caseId,
                    paymentData,
                    AckURL
                } = respStatus.data;
                let Mode_of_CME;
                let Applied_For;
                let paymentstatus = paymentData.RMK
                let paymentDate = paymentData.TET
                function formatDate(inputDate) {
                    // Parse the input date
                    const [year, month, day] = inputDate.split("-");
                    // Return the formatted date
                    return `${day}-${month}-${year}`;
                }

                let start_date = formatDate(cmeStartDate)
                let end_date = formatDate(cmeEndDate)

                if (isCmeOffline) {
                    Mode_of_CME = 'Offline'
                } else { Mode_of_CME = 'Online' }

                if (isInternationalFaculty) {
                    Applied_For = 'International'
                } else { Applied_For = 'National' }
                // Set state for all the relevant info on the page
                setCaseDetails({
                    _id,
                    organizationName,
                    email,
                    phone_number,
                    address,
                    theme,
                    registrationNumber,
                    start_date,
                    end_date,
                    daysDifference,
                    delegates,
                    exercise,
                    hours,
                    chairman,
                    secretary,
                    methodology,
                    pdfURL,
                    approvals,
                    alldocsverified,
                    time,
                    date,
                    certificateGenerated,
                    CRN,
                    isGovernmentOrganisation,
                    emailVerified,
                    uploadedCertificateURL,
                    isInternationalFaculty,
                    isCmeOffline,
                    caseId,
                    paymentstatus,
                    paymentDate,
                    Mode_of_CME,
                    Applied_For,
                    AckURL
                });

            } else {
                // Handle the failure case
                setError('OTP verification failed');
            }
        } catch (err) {
            setError('Failed to submit OTP.');
        } finally {
            setLoading(false);
        }
    };


    // const generatePdf = () => {
    //     const doc = new jsPDF();
    //     doc.setDrawColor(200, 0, 0);
    //     doc.setLineWidth(0.5);
    //     doc.rect(10, 10, 190, 277);
    //     const logoWidth = 40; // Desired width
    //     const logoHeight = 0; // Let the library calculate based on aspect ratio

    //     doc.addImage(logo, 'PNG', 20, 15, logoWidth, logoHeight);

    //     // Right-aligned heading
    //     doc.setTextColor(200, 0, 0);
    //     doc.setFont("times", "bold");
    //     doc.setFontSize(16);
    //     doc.text("Haryana Medical Council", 190, 20, { align: "right" });

    //     // Right-aligned address block
    //     doc.setTextColor(0, 0, 0);
    //     doc.setFont("times", "italic");
    //     doc.setFontSize(12);
    //     doc.text("SCO-410 2nd Floor, Sector-20, Panchkula-134116", 190, 25, { align: "right" });
    //     doc.text("Phone: 0172-4090410, 0172-2520165", 190, 30, { align: "right" });
    //     doc.text("Email: registrarhmc@gmail.com, info@haryanamedicalcouncil.org", 190, 35, { align: "right" });

    //     // Dynamic acknowledgment heading with date on the same line
    //     const currentDate = new Date().toLocaleDateString();
    //     const acknowledgmentText = "Acknowledgment";
    //     const acknowledgmentX = 130; // X-coordinate for the start of "Acknowledgment"
    //     const dateX = acknowledgmentX + doc.getTextWidth(acknowledgmentText) + 8; // Position date slightly after acknowledgment text

    //     doc.setFont("times", "bold");
    //     doc.setFontSize(14);
    //     doc.setTextColor(0, 0, 0); // Acknowledgment in black
    //     const acknowledgmentY = 60; // Adjust the Y-coordinate for placement
    //     doc.text(acknowledgmentText, acknowledgmentX, acknowledgmentY);

    //     doc.setTextColor(200, 0, 0); // Date in red
    //     doc.text(` - ${currentDate}`, dateX, acknowledgmentY);

    //     // Table Data
    //     const tableData = [
    //         ["Organization Name", `${caseDetails.organizationName}`],
    //         ["Name of the Organization Chairman", `${caseDetails.chairman}`],
    //         ["Name of the Organization Secretary", `${caseDetails.secretary}`],
    //         ["Phone Number", `${caseDetails.phone_number}`],
    //         ["Email", `${caseDetails.email}`],
    //         ["Venue of CME", { content: `${caseDetails.address}`, styles: { fontStyle: "bold" } }],
    //         ["Theme", { content: `${caseDetails.theme}`, styles: { fontStyle: "bold" } }],
    //         ["Case ID", `${caseDetails.caseId}`],
    //         ["CME Start Date to CME End Date", { content: `${caseDetails.start_date}-${caseDetails.end_date}`, styles: { fontStyle: "bold" } }],
    //         ["Applied For", `${caseDetails.Applied_For}`],
    //         ["Mode of CME", `${caseDetails.Mode_of_CME}`],
    //         ["Payment Amount", ""],
    //         ["Payment Method", ""],
    //         ["Transaction ID", ""],
    //         ["Payment Status", ""],
    //     ];

    //     doc.autoTable({
    //         startY: 65, // Start the table a bit lower
    //         body: tableData,
    //         styles: {
    //             halign: "left",
    //             valign: "middle",
    //             lineWidth: 0.05,
    //             lineColor: [0, 0, 0],
    //         },
    //         headStyles: {
    //             fillColor: [200, 0, 0],
    //             textColor: [255, 255, 255],
    //         },
    //         tableLineWidth: 0.1,
    //         tableLineColor: [0, 0, 0],
    //     });

    //     // Remarks section
    //     let finalY = doc.lastAutoTable.finalY + 10;
    //     doc.setFont("times", "normal");
    //     doc.setTextColor(200, 0, 0);
    //     doc.text("Remarks:", 15, finalY);
    //     doc.setTextColor(0, 0, 0);
    //     doc.text(
    //         `Please mention the ${caseDetails.caseId} for any future correspondences with reference to this application or use it to check the status of your application online.`,
    //         15,
    //         finalY + 5,
    //         { maxWidth: 175 }
    //     );

    //     // Note section
    //     finalY += 30;
    //     doc.setTextColor(200, 0, 0);
    //     doc.text("Note:", 15, finalY);
    //     doc.setTextColor(0, 0, 0);
    //     doc.text(
    //         "You are requested to submit hard copies (self-attested) of documents as per the checklist along with a copy of acknowledgment to Haryana Medical Council office 30 days prior to the Date of CME for processing your application. If documents are not received within the time period, your application may be rejected, and no claim for a refund of fees will be entertained.",
    //         15,
    //         finalY + 5,
    //         { maxWidth: 175 }
    //     );

    //     // Documents checklist
    //     finalY += 100;
    //     const checklist = [
    //         'Registration Number of Association / Organization with evidence',
    //         'List of the Faculty with detailed qualification and experience',
    //         'Detailed programme schedule mentioning time, subject and the name of the Speaker',
    //         'List of Topics',
    //         'Medical Registration Certificate of the Organizing Chairman',
    //         'Medical Registration Certificate of the Organizing Secretary',
    //         'Any Other Supporting Documents',
    //     ];

    //     const checklistTable = checklist.map((item, index) => [index + 1, item]);

    //     doc.setFontSize(16);
    //     doc.setTextColor(200, 0, 0);
    //     doc.text("Documents to be submitted in Haryana Medical Council office as per checklist", 15, finalY);

    //     finalY += 10;

    //     doc.autoTable({
    //         startY: finalY,
    //         body: checklistTable,
    //         head: [["S.No.", "Description"]],
    //         styles: {
    //             halign: "left",
    //             valign: "middle",
    //             lineWidth: 0.1,
    //             lineColor: [0, 0, 0],
    //         },
    //         headStyles: {
    //             fillColor: [200, 0, 0],
    //             textColor: [255, 255, 255],
    //         },
    //         tableLineWidth: 0.1,
    //         tableLineColor: [0, 0, 0],
    //     });

    //     doc.save("HaryanaMedicalCouncil.pdf");
    // };

    // const generatePdf = () => {
    //     const doc = new jsPDF();

    //     // Helper function to draw the border
    //     const drawBorder = () => {
    //         doc.setDrawColor(200, 0, 0);
    //         doc.setLineWidth(0.5);
    //         doc.rect(10, 10, 190, 277); // Border for the page
    //     };

    //     // Draw border for the first page
    //     drawBorder();

    //     const logoWidth = 40; // Desired width
    //     const logoHeight = 0; // Let the library calculate based on aspect ratio

    //     doc.addImage(logo, 'PNG', 20, 15, logoWidth, logoHeight);

    //     // Right-aligned heading
    //     doc.setTextColor(200, 0, 0);
    //     doc.setFont("times", "bold");
    //     doc.setFontSize(16);
    //     doc.text("Haryana Medical Council", 190, 25, { align: "right" });

    //     // Right-aligned address block
    //     doc.setTextColor(0, 0, 0);
    //     doc.setFont("times", "italic");
    //     doc.setFontSize(12);
    //     doc.text("SCO-410 2nd Floor, Sector-20, Panchkula-134116", 190, 30, { align: "right" });
    //     doc.text("Phone: 0172-4090410, 0172-2520165", 190, 35, { align: "right" });
    //     doc.text("Email: registrarhmc@gmail.com, info@haryanamedicalcouncil.org", 190, 40, { align: "right" });

    //     // Dynamic acknowledgment heading with date on the same line
    //     const currentDate = new Date().toLocaleDateString();
    //     const acknowledgmentText = "Acknowledgment";
    //     const acknowledgmentX = 130; // X-coordinate for the start of "Acknowledgment"
    //     const dateX = acknowledgmentX + doc.getTextWidth(acknowledgmentText) + 8; // Position date slightly after acknowledgment text

    //     doc.setFont("times", "bold");
    //     doc.setFontSize(14);
    //     doc.setTextColor(0, 0, 0); // Acknowledgment in black
    //     const acknowledgmentY = 60; // Adjust the Y-coordinate for placement
    //     doc.text(acknowledgmentText, acknowledgmentX, acknowledgmentY);

    //     doc.setTextColor(200, 0, 0); // Date in red
    //     doc.text(` - ${currentDate}`, dateX, acknowledgmentY);

    //     // Table Data
    //     const tableData = [
    //         ["Organization Name", `${caseDetails.organizationName}`],
    //         ["Name of the Organization Chairman", `${caseDetails.chairman}`],
    //         ["Name of the Organization Secretary", `${caseDetails.secretary}`],
    //         ["Phone Number", `${caseDetails.phone_number}`],
    //         ["Email", `${caseDetails.email}`],
    //         ["Venue of CME", { content: `${caseDetails.address}`, styles: { fontStyle: "bold" } }],
    //         ["Theme", { content: `${caseDetails.theme}`, styles: { fontStyle: "bold" } }],
    //         ["Case ID", `${caseDetails.caseId}`],
    //         ["CME Start Date to CME End Date", { content: `${caseDetails.start_date}-${caseDetails.end_date}`, styles: { fontStyle: "bold" } }],
    //         ["Applied For", `${caseDetails.Applied_For}`],
    //         ["Mode of CME", `${caseDetails.Mode_of_CME}`],
    //         ["Payment Amount", ""],
    //         ["Payment Method", ""],
    //         ["Transaction ID", ""],
    //         ["Payment Status", ""],
    //     ];

    //     doc.autoTable({
    //         startY: 65, // Start the table a bit lower
    //         body: tableData,
    //         styles: {
    //             halign: "left",
    //             valign: "middle",
    //             lineWidth: 0.05,
    //             lineColor: [0, 0, 0],
    //         },
    //         headStyles: {
    //             fillColor: [200, 0, 0],
    //             textColor: [255, 255, 255],
    //         },
    //         tableLineWidth: 0.1,
    //         tableLineColor: [0, 0, 0],
    //     });

    //     // Remarks section
    //     let finalY = doc.lastAutoTable.finalY + 10;
    //     doc.setFont("times", "normal");
    //     doc.setTextColor(200, 0, 0);
    //     doc.text("Remarks:", 15, finalY);
    //     doc.setTextColor(0, 0, 0);
    //     doc.text(
    //         `Please mention the ${caseDetails.caseId} for any future correspondences with reference to this application or use it to check the status of your application online.`,
    //         15,
    //         finalY + 5,
    //         { maxWidth: 175 }
    //     );

    //     // Note section
    //     finalY += 30;
    //     doc.setTextColor(200, 0, 0);
    //     doc.text("Note:", 15, finalY);
    //     doc.setTextColor(0, 0, 0);
    //     doc.text(
    //         "You are requested to submit hard copies (self-attested) of documents as per the checklist along with a copy of acknowledgment to Haryana Medical Council office 30 days prior to the Date of CME for processing your application. If documents are not received within the time period, your application may be rejected, and no claim for a refund of fees will be entertained.",
    //         15,
    //         finalY + 5,
    //         { maxWidth: 175 }
    //     );

    //     // Documents checklist
    //     finalY += 140;
    //     const checklist = [
    //         'Registration Number of Association / Organization with evidence',
    //         'List of the Faculty with detailed qualification and experience',
    //         'Detailed programme schedule mentioning time, subject and the name of the Speaker',
    //         'List of Topics',
    //         'Medical Registration Certificate of the Organizing Chairman',
    //         'Medical Registration Certificate of the Organizing Secretary',
    //         'Any Other Supporting Documents',
    //     ];

    //     const checklistTable = checklist.map((item, index) => [index + 1, item]);

    //     doc.setFontSize(16);
    //     doc.setTextColor(200, 0, 0);


    //     // If the content overflows, add a new page
    //     if (finalY > 277) {
    //         doc.addPage();
    //         // Draw the border on the new page
    //         drawBorder();
    //         // Reset finalY for the new page
    //         finalY = 20;
    //     }
    //     doc.text("Documents to be submitted in Haryana Medical Council office as per checklist", 15, finalY);

    //     finalY += 10;
    //     // Add the checklist table only once
    //     doc.autoTable({
    //         startY: finalY,
    //         body: checklistTable,
    //         head: [["S.No.", "Description"]],
    //         styles: {
    //             halign: "left",
    //             valign: "middle",
    //             lineWidth: 0.1,
    //             lineColor: [0, 0, 0],
    //         },
    //         headStyles: {
    //             fillColor: [200, 0, 0],
    //             textColor: [255, 255, 255],
    //         },
    //         tableLineWidth: 0.1,
    //         tableLineColor: [0, 0, 0],
    //     });

    //     doc.save("HaryanaMedicalCouncil.pdf");
    // };



    const downlaodPdf = () => {
        if (caseDetails?.AckURL) {
            // Open the S3 URL in a new tab
            const pdfUrl = caseDetails.AckURL;
    
            // Using window.open to open the URL in a new tab
            window.open(pdfUrl, '_blank');
        } else {
            console.error("AckURL is not available in caseDetails");
        }
    
        console.log(caseDetails?.AckURL);
        console.log(caseDetails);
    };
    


    const RetryPayment = async() => {
        const response = await axios.get(`${endpoint}/generatepaymentstring?id=${caseDetails._id}`);



         const paymentUrl = `https://haryanamedicalcouncil.org/Home/cmeRegistration?string=${response.data}`;
        // const paymentUrl = `https://haryanamedicalcouncil.org/Home/cmeRegistration/string?=${encryptedString}`;

        // Open the URL in a new tab
        // console.log(paymentUrl)
        // window.open(paymentUrl, "_blank");
        window.location.href = paymentUrl;


    }




    return (
        <>
            <Nav />

            <div className="ViewStatusPage">
                <div className="ViewStatus">
                    <div className="dashHeading">Your Registration Status</div>

                    {/* Loading Indicator */}
                    {loading && <div className="loading-indicator">Loading...</div>}

                    {/* Error Handling */}
                    {error && <div className="error-message">{error}</div>}

                    {/* OTP Page */}
                    {isIdValid && showOtpPage && (
                        <div className="otp-page">
                            <h2>Enter OTP</h2>
                            <input
                                type="text"
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                            />
                            <button onClick={handleOtpSubmit} disabled={loading}>
                                Submit
                            </button>
                        </div>
                    )}

                    {/* Case Details Section */}
                    {!showOtpPage && caseDetails && (
                        <div className="case-details-section">
                            {caseDetails?.paymentstatus ? (
                                <button onClick={() => { downlaodPdf() }}>download payment ack</button>
                            ) : (
                                <></>
                            )}

                            {caseDetails?.paymentstatus === 'failure' && (
                                <button
                                    onClick={() => { RetryPayment() }}
                                    style={{
                                        backgroundColor: 'green',
                                        color: 'white',
                                        border: 'none',
                                        padding: '10px 7px',
                                        fontSize: '16px',
                                        marginLeft:'10px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        display: 'inline-block'  // Keeps it inline
                                    }}
                                >
                                    Retry Payment
                                </button>
                            )}






                            <h2>Case Details</h2>
                            <table className="status-table">
                                <thead>
                                    <tr>
                                        <th>Field</th>
                                        <th>Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* <tr>
                                        <td>Organization Name</td>
                                        <td>{caseDetails?.organizationName || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Email</td>
                                        <td>{caseDetails?.email || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Phone Number</td>
                                        <td>{caseDetails?.phone_number || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Address of Organisation</td>
                                        <td>{caseDetails?.address || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Theme</td>
                                        <td>{caseDetails?.theme || "Loading..."}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Registration Number</td>
                                        <td>{caseDetails?.registrationNumber || "Loading..."}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>CME Start Date</td>
                                        <td>{caseDetails?.cmeStartDate || "Loading..."}</td>
                                    </tr>
                                    <tr>
                                        <td>CME End Date</td>
                                        <td>{caseDetails?.cmeEndDate || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Days Difference</td>
                                        <td>{caseDetails?.daysDifference || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>No. of Delegates</td>
                                        <td>{caseDetails?.delegates || "Loading..."}</td>
                                    </tr>
                                    <tr>
                                        <td>Exercise</td>
                                        <td>{caseDetails?.exercise || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Hours</td>
                                        <td>{caseDetails?.hours || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Chairman</td>
                                        <td>{caseDetails?.chairman || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Secretary</td>
                                        <td>{caseDetails?.secretary || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Methodology</td>
                                        <td>{caseDetails?.methodology || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>PDF URLs</td>
                                        <td>
                                            {caseDetails?.pdfURL?.map((pdf, index) => (
                                                <div key={index}>
                                                    <a href={pdf.url} target="_blank" rel="noopener noreferrer">
                                                        {pdf.PDF_Label}
                                                    </a>
                                                    <span> - {pdf.approved ? "Approved" : "Not Approved"}</span>
                                                </div>
                                            )) || "N/A"}
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>Approval - Clerk</td>
                                        <td>{caseDetails?.approvals?.clerk ? "Approved" : "Not Approved"}</td>
                                    </tr>
                                    <tr>
                                        <td>Approval - Registrar</td>
                                        <td>{caseDetails?.approvals?.registrar ? "Approved" : "Not Approved"}</td>
                                    </tr>
                                    <tr>
                                        <td>Approval - Accountant</td>
                                        <td>{caseDetails?.approvals?.accountant ? "Approved" : "Not Approved"}</td>
                                    </tr>
                                    <tr>
                                        <td>Approval - President</td>
                                        <td>{caseDetails?.approvals?.president ? "Approved" : "Not Approved"}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>All Docs Verified</td>
                                        <td>{caseDetails?.alldocsverified ? "Yes" : "No"}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Time</td>
                                        <td>{caseDetails?.time || "Loading..."}</td>
                                    </tr>
                                    <tr>
                                        <td>Date</td>
                                        <td>{caseDetails?.date || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Certificate Generated</td>
                                        <td>{caseDetails?.certificateGenerated ? "Yes" : "No"}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Case Id</td>
                                        <td>{caseDetails?.CRN || "Loading..."}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Government Organization</td>
                                        <td>{caseDetails?.isGovernmentOrganisation ? "Yes" : "No"}</td>
                                    </tr> */}
                                    <tr>
                                        <td>Email Verified</td>
                                        <td>{caseDetails?.emailVerified ? "Verified" : "Not Verified"}</td>
                                    </tr>
                                    {/* <tr>
                                        <td>Uploaded Certificate URL</td>
                                        <td>
                                            {caseDetails?.uploadedCertificateURL?.length > 0
                                                ? caseDetails.uploadedCertificateURL.map((url, index) => (
                                                    <div key={index}>
                                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                                            {url}
                                                        </a>
                                                    </div>
                                                ))
                                                : "N/A"}
                                        </td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>International Faculty</td>
                                        <td>{caseDetails?.isInternationalFaculty ? "Yes" : "No"}</td>
                                    </tr>
                                    <tr>
                                        <td>CME Offline</td>
                                        <td>{caseDetails?.isCmeOffline ? "Yes" : "No"}</td>
                                    </tr> */}
                                    {/* <tr>
                                        <td>Case ID</td>
                                        <td>{caseDetails?.caseId || "Loading..."}</td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    )}

                </div>
            </div>

            <Footer />
        </>
    );
};

export default ViewStatus;
